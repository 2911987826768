import React, { useState, useEffect } from "react";
import axios from "axios";
import { dbLyricsQuiz } from "../Constants"; // ✅ Import API URL

const LyricsQuiz = () => {
  const [data, setData] = useState([]); // ✅ Stores fetched data
  const [loading, setLoading] = useState(false); // ✅ Prevents multiple API calls
  const [error, setError] = useState(null); // ✅ Track errors

  // ✅ Fetch Data When Component Mounts
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        console.log("Fetching all data..."); // ✅ Debugging output

        const response = await axios.get(dbLyricsQuiz); // ✅ No pagination params

        console.log("API Response:", response.data); // ✅ Debugging output

        setData(response.data); // ✅ Save the entire dataset at once
        setError(null);
      } catch (err) {
        console.error("Fetch error:", err);
        setError(err.message);
      } finally {
        setLoading(false); // ✅ Ensures spinner stops
      }
    };

    fetchData(); // ✅ Runs only once when the component mounts
  }, []); // ✅ Empty dependency array ensures no re-runs

  return (
    <div className="container py-4">
      <h2 className="mb-4">Fetched Data</h2>

      {/* 🚀 Data List */}
      <div className="row">
        {data.length > 0 ? (
          data.map((item) => (
            <div key={item.id} className="col-md-4 mb-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title">{item.title}</h5>
                  <p className="card-text Description">{item.lyrics}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          !loading && <p className="text-center text-muted">No data available</p>
        )}
      </div>

      {/* 🔄 Loading Indicator (Only Shows While Fetching) */}
      {loading && (
        <div className="text-center mt-4">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p>Loading...</p>
        </div>
      )}

      {/* ❌ Error Message */}
      {error && (
        <div className="text-center mt-4 text-danger">
          <p>Error: {error}</p>
          <button onClick={() => window.location.reload()} className="btn btn-outline-danger mt-2">
            Retry
          </button>
        </div>
      )}
    </div>
  );
};

export default LyricsQuiz;
