//export const dbURL = "https://api2.musicthisday.com/";
export const dbURL = "https://api2204.musicthisday.com/";
//export const dbURL = "http://localhost:3002/";
export const dbURLpeople = dbURL +'profiles/';
export const dbURLGreatConcerts = dbURL +'greatconcert/';
export const dbURLBestAlbums = dbURL +'bestalbum/';
export const dbURLBestAlbumsLive = dbURL +'selectedlivesongalbum/';
export const dbURLBestSongs = dbURL +'bestsong/';
export const dbURLBestSongsLive = dbURL +'selectedlivesong/';
export const dbURLPeopleLists = dbURL +'listpeople/';
export const dbURLPeopleListsUnranked = dbURL +'listpeopleunranked/';
export const dbURLists = dbURL +'listslug/';
//NEW - REFERENCES
export const dbURListsRefs = dbURL +'listrefs/';

export const dbURLBobDylanSongsLists = dbURL +'bobdylanlistssongs/';
export const dbURLBobDylanSongsListsLive = dbURL +'ranketlivesongsdylan/';

//New songslive
export const dbURLSongsLive = dbURL +'songslive/';

export const dbURLPostHeading = dbURL +'post/';
export const dbURLPostSessions = dbURL +'postsessions/';

export const duURLVideoAudioOther = dbURL +'vaother/';

export const dbURLConcertListsHeading = dbURL +'concertslistheading/';
export const dbURLConcerts = dbURL +'concertslists/';
export const dbURLConcert = dbURL +'concert/';

//export const dbURLCoverVersionsHeading = dbURL +'coversongsheading/';
export const dbURLCoverVersions = dbURL +'coverversions/';

//Dylan covers
export const dbURLDylanCoversHeading = dbURL +'dylancoversheading/';
export const dbURLDylanCovers = dbURL +'dylancovers/';

//export const dbURL = "http://157.230.236.104/";
export const dbURLlocal = "http://localhost:3002/";

//DylanAnyDay URL
export const dbURLAnyDayAlbumsDylan = dbURL +'dylanalbumsanyday/';
export const dbURLAnyDayConcertsDylan = dbURL +'dylanconcertsanyday/';
export const dbURLAnyDaySongsDylan = dbURL +'dylansongsanyday/';
export const dbURLAnyDaySessionsDylan = dbURL +'dylansessionsanyday/';
export const dbURLAnyDayPeopleDylan = dbURL +'dylanpeopleanyday/';
export const dbURLAnyDayHistoryDylan = dbURL +'dylanhistoryanyday/';

//Music Any Day
export const dbURLAnyDayAlbums = dbURL +'albumsanyday/';
export const dbURLAnyDayPeople = dbURL +'peopleanyday/';
export const dbURLAnyDaySongs = dbURL +'songsanyday/';
export const dbURLAnyDayConcerts =dbURL +'concertsanyday/';

//words
export const dbUrlQuotes = dbURL + 'quotes/';
export const dbUrlQuotesMTD = dbURL + 'quotesmtd/';
export const dbURLInterviews = dbURL + 'interviews/';
export const dbURLInterviewsAll = dbURL + 'interviews-all';

//timelines
export const dbDylanTimelineInterviews = dbURL + 'timeline-dylan-interviews/';
export const dbDylanTimelineHistory = dbURL + 'timeline-dylan-history/';
export const dbDylanTimelinePeople = dbURL + 'timeline-dylan-people/';
export const dbDylanTimelineRecsessions = dbURL + 'timeline-dylan-recsessions/';
export const dbDylanTimelineSongs = dbURL + 'timeline-dylan-songs/';
export const dbDylanTimelineAlbums = dbURL + 'timeline-dylan-albums/';

export const dbMusicTimelineHistory = dbURL + 'timeline-music-history/';
export const dbMusicTimelineAlbums = dbURL + 'timeline-music-albums/';
export const dbMusicTimelineSongs = dbURL + 'timeline-music-songs/';
export const dbMusicTimelinePeople = dbURL + 'timeline-music-people/';
export const dbMusicTimelineConcerts = dbURL + 'timeline-music-concerts/';

//bootleg
export const dbBootleg = dbURL + 'bootleg/';

//video
export const dbVideo = dbURL + 'video/';

//news
export const dbNews = dbURL + 'activenews/';

//lyrics quiz
export const dbLyricsQuiz = dbURL + 'lyricsquiz/';






export const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '25px',
      width: '165px',
      boxShadow: state.isFocused ? null : null,
    }),

     container: (provided, state) => ({
        ...provided,
        height: '35px',
        width: '150px',
        //padding: '0 6px'
      }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
    }),
  };

export const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '25px',
      width: '265px',
      boxShadow: state.isFocused ? null : null,
    }),

     container: (provided, state) => ({
        ...provided,
        height: '28px',
        width: '265px',
        padding: '0 6px'
      }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      width: '250px',
      //padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
    }),
  };

export const customStyles3 = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '25px',
      width: '200px',
      boxShadow: state.isFocused ? null : null,
    }),

     container: (provided, state) => ({
        ...provided,
        height: '35px',
        width: '200px',
        //padding: '0 6px'
      }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
    }),
  };


export const customStyles4 = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '25px',
      width: '175px',
      boxShadow: state.isFocused ? null : null,
    }),

     container: (provided, state) => ({
        ...provided,
        height: '35px',
        width: '165px',
        //padding: '0 6px'
      }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
    }),
  };

  export const customStyles200 = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '25px',
      width: '230px',
      boxShadow: state.isFocused ? null : null,
    }),

     container: (provided, state) => ({
        ...provided,
        height: '35px',
        width: '165px',
        //padding: '0 6px'
      }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
    }),
  };

  export const customStylesSmall = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '25px',
      width: '200px',
      boxShadow: state.isFocused ? null : null,
    }),
  
     container: (provided, state) => ({
        ...provided,
        height: '35px',
        width: '210px',
        //padding: '0 6px'
      }),
  
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
      padding: '0 6px'
    }),
  
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
    }),
  };